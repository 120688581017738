import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    IconButton, ButtonGroup, Modal, Paper, Snackbar
} from '@mui/material';
import Button from '@mui/material/Button';
import {
    ZoomIn, ZoomOut
} from '@material-ui/icons';
import ShareIcon from '@mui/icons-material/Share';
import {
    selectCurrentPhoto, selectMaximized, setMaximized, setCurrentPhoto
} from './photoViewSlice';
import { selectLandmarks, selectCloserLookLandmarks } from '../landmark/landmarkSlice';
import { setCurrentCloserLook } from '../closerLook/closerLookSlice';
import './photoView.css';
import {
    generateStreetViewLink, generateMapsLink, sharePhotoLink
} from '../../app/utilities';

export default function PhotoView(props) {
    const { mapRef } = props;
    const [openToast, setOpenToast] = React.useState(false);
    const photo = useSelector(selectCurrentPhoto);
    const landmarks = useSelector(selectLandmarks);
    const closerLookLandmarks = useSelector(selectCloserLookLandmarks);
    const maximized = useSelector(selectMaximized);
    const dispatch = useDispatch();
    let associatedLandmark = null;

    function findLandmark(uid) {
        return landmarks.find((landmark) => landmark.properties.uid === uid);
    }
    function closePhotoView() {
        dispatch(setCurrentPhoto(null));
        dispatch(setMaximized(false));
    }
    function handleClose(event, reason) {
        if (reason === 'backdropClick') {
            closePhotoView();
        }
    }
    // eslint-disable-next-line consistent-return
    function determineImageSize() {
        const urlParams = new URLSearchParams({
            id: photo.id
        });
        window.history.replaceState(null, '', `image?${urlParams.toString()}`);
        let portrait = false;
        const clientWidth = window.innerWidth || document.documentElement.clientWidth
        || document.body.clientWidth;
        const clientHeight = window.innerHeight || document.documentElement.clientHeight
        || document.body.clientHeight;

        if (clientHeight > clientWidth) {
            portrait = true;
        }

        if (maximized) {
            return portrait ? { width: 'calc(90vw)', height: 'auto' } : { width: 'auto', height: 'calc(70vh)' };
        // eslint-disable-next-line no-else-return
        }
        return portrait ? { width: 'calc(65vw)', height: 'auto' } : { width: 'auto', height: 'calc(40vh)' };
    }

    function shareAction(id) {
        sharePhotoLink(id);
        setOpenToast(true);
    }
    function closeToast() {
        setOpenToast(false);
    }

    if (photo && (photo.associatedLandmark && landmarks)) {
        associatedLandmark = findLandmark(photo.associatedLandmark);
    }
    return (
        photo ? (
            <Modal
                open={Boolean(photo)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
                onContextMenu={(e) => e.preventDefault()}
                disableScrollLock
                className="centered-modal-placement"
                onClose={(event, reason) => handleClose(event, reason)}
            >
                <Paper className="photo-view">
                    <ButtonGroup disableElevation>
                        <Button size="small" aria-label="Zoom" onClick={() => { dispatch(setMaximized(!maximized)); }}>
                            {maximized ? <ZoomOut /> : <ZoomIn /> }
                        </Button>
                        { photo.streetView ? (
                            <Button
                                size="small"
                                aria-label="Street View"
                                style={{ backgroundColor: '#fbbc05', color: 'white' }}
                                href={generateStreetViewLink(photo)}
                                target="_blank"
                            >
                                <img src="png/street-view.png" style={{ width: '1em' }} />
                            </Button>
                        ) : null }

                        <Button
                            size="small"
                            aria-label="Maps"
                            style={{ backgroundColor: '#4285f4', color: 'white' }}
                            href={generateMapsLink(photo)}
                            target="_blank"
                        >
                            <img src="png/google-maps.png" style={{ width: '1em' }} />
                        </Button>
                    </ButtonGroup>
                    <IconButton size="small" variant="outlined" className="share-photo-button" onClick={() => shareAction(photo.id)}><ShareIcon /></IconButton>
                    <br />
                    <br />
                    <img
                        src={photo.filePath}
                        alt=""
                        style={determineImageSize()}
                    />
                    <p style={{ fontSize: '0.8em', marginTop: '-0.25em' }}>{photo.attribution}</p>
                    <p style={{ textAlign: 'center', marginTop: '1em' }}>
                        {' '}
                        {photo.description}
                        {' '}
                    </p>
                    {' '}

                    { associatedLandmark ? (
                        <span>
                            { closerLookLandmarks.includes(photo.associatedLandmark) ? (
                                <span>
                                    <h3>Associated Landmark:</h3>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            dispatch(setCurrentCloserLook(associatedLandmark));
                                            closePhotoView();
                                            mapRef.current.target.setView([associatedLandmark.geometry.coordinates[1], associatedLandmark.geometry.coordinates[0] - 0.0045], 16);
                                        }}
                                        href="#"
                                        disableElevation
                                        size="small"
                                    >
                                        {associatedLandmark.properties.name}
                            ,
                                        {' '}
                                        {parseInt(associatedLandmark.properties.year, 10)}
                                        {' '}
                                    </Button>
                                </span>
                            ) : null}
                        </span>
                    ) : null }
                    <Button variant="contained" onClick={() => { closePhotoView(); }} className="close-button" size="small">Close</Button>
                    <Snackbar
                        open={openToast}
                        autoHideDuration={3000}
                        onClose={closeToast}
                        message="Link copied"
                    />
                </Paper>
            </Modal>
        ) : null
    );
}
