import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    northernNeckGrantsLayerEnabled: false,
    northernNeckGrants: null,
    civilWarPropertiesLayerEnabled: false,
    civilWarProperties: null,
    properties1958LayerEnabled: false,
    aerial1937Enabled: false,
    cemeteries: null,
    places: null,
    photos: null,
    map: null,
    currentCommunity: null,
    sharedUrl: null
};

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setNorthernNeckGrantsLayerEnabled: (state, action) => {
            state.northernNeckGrantsLayerEnabled = action.payload;
        },
        setNorthernNeckGrants: (state, action) => {
            state.northernNeckGrants = action.payload;
        },
        setCivilWarPropertiesLayerEnabled: (state, action) => {
            state.civilWarPropertiesLayerEnabled = action.payload;
        },
        setCivilWarProperties: (state, action) => {
            state.civilWarProperties = action.payload;
        },
        set1958PropertiesLayerEnabled: (state, action) => {
            state.properties1958LayerEnabled = action.payload;
        },
        setPlaces: (state, action) => {
            state.places = action.payload;
        },
        setPhotos: (state, action) => {
            state.photos = action.payload;
        },
        setCemeteries: (state, action) => {
            state.cemeteries = action.payload;
        },
        setMap: (state, action) => {
            state.map = action.payload;
        },
        setCurrentCommunity: (state, action) => {
            state.currentCommunity = action.payload;
        },
        setSharedUrl: (state, action) => {
            state.sharedUrl = action.payload;
        },
        setSeenSharedContent: (state, action) => {
            state.seenSharedContent = action.payload;
        },
        setAerial1937Enabled: (state, action) => {
            state.aerial1937Enabled = action.payload;
        }
    }
});

export const {
    setPlaces,
    setMap,
    setCivilWarPropertiesLayerEnabled,
    setCivilWarProperties,
    setPhotos,
    setNorthernNeckGrantsLayerEnabled,
    setNorthernNeckGrants,
    setCemeteries,
    set1958PropertiesLayerEnabled,
    setCurrentCommunity,
    setSharedUrl,
    setSeenSharedContent,
    setAerial1937Enabled
} = mapSlice.actions;

export const selectCenter = (state) => state.map.center;
export const selectZoom = (state) => state.map.zoom;
export const selectNorthernNeckGrantsLayerEnabled = (state) => state.map.northernNeckGrantsLayerEnabled;
export const selectNorthernNeckGrants = (state) => state.map.northernNeckGrants;
export const selectCivilWarProperties = (state) => state.map.civilWarProperties;
export const selectCivilWarPropertiesLayerEnabled = (state) => state.map.civilWarPropertiesLayerEnabled;
export const select1958PropertiesLayerEnabled = (state) => state.map.properties1958LayerEnabled;
export const selectCemeteries = (state) => state.map.cemeteries;
export const selectPlaces = (state) => state.map.places;
export const selectPhotos = (state) => state.map.photos;
export const selectMap = (state) => state.map.map;
export const selectCurrentCommunity = (state) => state.map.currentCommunity;
export const selectSharedUrl = (state) => state.map.sharedUrl;
export const selectSeenSharedContent = (state) => state.map.seenSharedContent;
export const selectAerial1937Enabled = (state) => state.map.aerial1937Enabled;

export default mapSlice.reducer;
