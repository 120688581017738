import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    year: 1957,
    showAll: false
};

export const yearSlice = createSlice({
    name: 'year',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setYear: (state, action) => {
            state.year = action.payload;
        },
        setShowAll: (state, action) => {
            state.showAll = action.payload;
        }
    }
});

export const { setYear, setShowAll } = yearSlice.actions;

export const selectYear = (state) => state.year.year;

export const showAll = (state) => state.year.showAll;

export default yearSlice.reducer;
