/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import { React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    FormControl,
    Input,
    Checkbox,
    ListItemText,
    ListSubheader,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import { platGroups } from '../../app/plats';
import { setCurrentPlats, selectCurrentPlats } from './platSlice';

export default function PlatPicker() {
    const dispatch = useDispatch();
    const currentPlats = useSelector(selectCurrentPlats);
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        dispatch(setCurrentPlats(typeof value === 'string' ? value.split(',') : value));
    };

    function selectedNames(selectedPlats) {
        const flatPlats = [];
        platGroups.forEach((platGroup) => {
            platGroup.plats.forEach((platGroupPlat) => flatPlats.push(platGroupPlat));
        });
        return selectedPlats.map((selectedPlat) => flatPlats.find((plat) => plat.layer === selectedPlat).name).join(', ');
    }

    return (
        <div>
            <FormControl sx={{ m: 1, width: 275, marginTop: -1 }} variant="filled" size="small">
                <InputLabel sx={{ marginTop: '0.5em' }}>Drawn Plats</InputLabel>
                <Select
                    onChange={handleChange}
                    multiple
                    input={<Input />}
                    value={currentPlats}
                    renderValue={
                        (selected) => selectedNames(selected)
                    }
                    style={
                        {
                            backgroundColor: 'white', height: 36, borderRadius: 4, padding: 5, paddingTop: 6, paddingLeft: 7
                        }
                    }
                >
                    {
                        platGroups.map((platGroup) => [
                            <ListSubheader>{platGroup.title}</ListSubheader>,
                            platGroup.plats.map((plat) => (
                                <MenuItem key={plat.name} value={plat.layer} dense>
                                    <Checkbox checked={currentPlats.indexOf(plat.layer) > -1} />
                                    <ListItemText primary={`${plat.name} (${plat.year})`} />
                                </MenuItem>
                            ))
                        ])
                    }
                    {/* {plats.map((plat) => (
                        <MenuItem key={plat.name} value={plat.layer}>
                            <Checkbox checked={currentPlats.indexOf(plat.layer) > -1} />
                            <ListItemText primary={plat.name} />
                        </MenuItem>
                    ))} */}
                </Select>
            </FormControl>
        </div>
    );
}
