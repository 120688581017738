/* eslint-disable no-loss-of-precision */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPlats: [],
    plats: []
};

export const platSlice = createSlice({
    name: 'plat',
    initialState,
    reducers: {
        setCurrentPlats: (state, action) => {
            state.currentPlats = action.payload;
        }
    }
});

export const {
    setCurrentPlats
} = platSlice.actions;

export const selectCurrentPlats = (state) => state.plat.currentPlats;
export default platSlice.reducer;
