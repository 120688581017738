/* eslint-disable no-loss-of-precision */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentStory: null,
    stories: [],
    firstMarkerPosition: { lat: 0, lng: 0 },
    storyPlaying: false
};

export const storySlice = createSlice({
    name: 'story',
    initialState,
    reducers: {
        setCurrentStory: (state, action) => {
            state.currentStory = action.payload;
        },
        setFirstMarkerPosition: (state, action) => {
            state.stories = action.payload;
        },
        setStoryPlaying: (state, action) => {
            state.storyPlaying = action.payload;
        }
    }
});

export const {
    setCurrentStory,
    setFirstMarkerPosition,
    setStoryPlaying
} = storySlice.actions;

export const selectCurrentStory = (state) => state.story.currentStory;
export const selectFirstMarkerPosition = (state) => state.story.firstMarkerPosition;
export const selectStoryPlaying = (state) => state.story.storyPlaying;
export default storySlice.reducer;
