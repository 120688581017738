export const platGroups = [
    {
        title: 'Chantilly',
        plats: [
            {
                layer: '059_1908_001_0257p_modified',
                case: 'Simpson v. Turley',
                year: 1908,
                index: '1908-001',
                name: 'Simpson v. Turley 2',
                type: 'chancery',
                center: [38.91640848682415, -77.42980478615789]
            },
            {
                layer: '059_1908_001_0259p_modified',
                case: 'Simpson v. Turley',
                year: 1908,
                index: '1908-001',
                name: 'Simpson v. Turley 3',
                type: 'chancery',
                center: [38.911842722338, -77.42839030119251]
            },
            {
                layer: '059_1891_026_0098p_modified',
                case: 'Lee v. Haight',
                year: 1891,
                index: '1891-026',
                name: 'Lee v. Haight',
                type: 'chancery',
                center: [38.89847531666623, -77.43800570208293]
            },
            {
                layer: '059_1905_007_0022p_modified',
                case: 'James v. Vansickler',
                year: 1905,
                index: '1905-007',
                name: 'James v. Vansickler',
                type: 'chancery',
                center: [38.9019751333953, -77.4492436987758]
            }
        ]
    },
    {
        title: 'Willard',
        plats: [
            {
                layer: '059_1908_001_0154p_modified',
                case: 'Simpson v. Turley',
                year: 1908,
                index: '1908-001',
                name: 'Simpson v. Turley 1',
                type: 'chancery',
                center: [38.9305831776124, -77.43769377571854]
            },
            {
                layer: '059_1908_001_0260p_modified',
                case: 'Simpson v. Turley',
                year: 1908,
                index: '1908-001',
                name: 'Simpson v. Turley 4',
                type: 'chancery',
                center: [38.92481698767351, -77.43754369191203]
            },
            {
                layer: '107_1870_055_0020p_modified',
                case: 'Moxley v. Moxley',
                year: 1870,
                index: '1870-055',
                name: 'Moxley v. Moxley',
                type: 'chancery',
                center: [38.928841869074716, -77.46891949322583]
            },
            {
                layer: '059_1834_004_0024p_modified',
                case: 'Whaley v. Turley',
                year: 1834,
                index: '1834-004',
                name: 'Whaley v. Turley',
                type: 'chancery',
                center: [38.92803333057664, -77.45888242014561]
            }
        ]
    },
    {
        title: 'Arcola',
        plats: [
            {
                layer: '107_1866_063_0023p_modified',
                case: 'Shreve v. Warfield',
                year: 1866,
                index: '1866-063',
                name: 'Shreve v. Warfield',
                type: 'chancery',
                center: [38.96515047336165, -77.47990922560558]
            }
        ]
    },
    {
        title: 'Pleasant Valley',
        plats: [
            {
                layer: '107_1848_039_0010p_modified',
                case: 'Whaley v. Whaley',
                year: 1848,
                index: '1848-039',
                name: 'Whaley v. Whaley',
                type: 'chancery',
                center: [38.91657144806335, -77.5084045488091]
            }
        ]
    },
    {
        title: 'Floris',
        plats: [
            {
                case: 'Fairfax to Carter',
                name: '"King" Carter\'s Northern Neck Grant',
                type: 'deed',
                layer: 'carter_e180_modified',
                index: 'E:180',
                year: 1729,
                center: [38.93584, -77.41635]
            },
            {
                case: 'Carter to Ratcliffe',
                layer: 'DEED BK00054 PG0419_modified',
                name: 'Carter to Ratcliffe',
                index: 'B3:417',
                year: 1835,
                type: 'deed',
                center: [38.927291, -77.423645]
            }
        ]
    }
];
