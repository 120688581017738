import React, { useEffect, useState, useRef } from 'react';
import {
    Accordion, AccordionSummary, AccordionDetails, Button, Box,
    Card, CardMedia, CardContent, IconButton, Paper, Modal, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isIPad13 } from 'react-device-detect';
import './dialog.css';
import { setAbout, selectAbout, setTutorial } from './dialogSlice';
import { selectSharedUrl } from '../map/mapSlice';

export default function About() {
    const dispatch = useDispatch();

    const isSharedUrl = useSelector(selectSharedUrl);
    const aboutOpen = useSelector(selectAbout);

    function aboutShown() {
        const timestamp = window.localStorage.getItem('shownAbout');
        if (timestamp) {
            // Calculate the current time in milliseconds
            const currentTime = new Date().getTime();
            // Calculate the time difference in milliseconds
            const timeDifference = currentTime - timestamp;
            // Calculate the number of milliseconds in 8 hours
            const eightHoursInMilliseconds = 8 * 60 * 60 * 1000;

            // Check if 8 hours have passed
            return timeDifference <= eightHoursInMilliseconds;
        }
        return false;
    }

    useEffect(() => {
        if (!aboutShown()) {
            dispatch(setAbout(true));
        }
    }, []);

    function closeAbout() {
        dispatch(setAbout(false));
        window.localStorage.setItem('shownAbout', Date.now());
    }
    function handleClose(event, reason) {
        if (reason === 'backdropClick') {
            closeAbout();
        }
    }
    function showAbout() {
        return aboutOpen && !isSharedUrl;
    }
    function openTutorial() {
        dispatch(setTutorial(true));
    }
    const closeButtonStyle = {
        position: 'absolute',
        right: 25,
        top: 10,
        backgroundColor: 'white',
        zIndex: 1000
    };

    return (
        <Modal
            open={Boolean(showAbout())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
            disableScrollLock
            hideBackdrop
            className={(!isMobile || isIPad13) ? 'standard-modal-placement' : 'mobile-modal-placement'}
            onClose={(event, reason) => handleClose(event, reason)}
        >
            <Paper className="about">
                <Card>
                    <IconButton size="small" onClick={() => closeAbout()} style={closeButtonStyle}><CloseIcon /></IconButton>
                    <CardMedia
                        component="video"
                        image="/videos/about_stable_diffusion_header.mp4"
                        title="Made with Stable Video Diffusion AI"
                        className="header-video"
                        loop
                        autoPlay
                    />
                    <CardContent>
                        {(isMobile && !isIPad13) ? (
                            <Typography variant="h6" align="center" style={{ color: 'red' }} gutterBottom>
                                <strong>NOTE:</strong> Mobile version features limited functionality. Computer or tablet is recommended.
                            </Typography>
                        ) : null }
                        <Typography variant="h5" align="left" gutterBottom>
                            About
                        </Typography>
                        <p>Heritage Dulles is an interactive map that allows you to explore the area within and around Dulles Airport over multiple time periods.</p><p>From the displacement of indigenous inhabitants by Western colonists, the rise of the tobacco economy cultivated by enslaved hands, the growth and development of communities shaped by turnpikes and railroads, the echoes of Civil War conflicts, to the rise and the abrupt fall of a thriving black farming community by the transition to the jet age, this area, now transformed into a bustling suburban hub with an unmatched economy built on information technology, stands as a living microcosm of the full American history.</p><p>Heritage Dulles is a ongoing project with content being added over time.</p>

                        {(!isMobile || isIPad13) ? <Button onClick={() => { openTutorial(); }} variant="outlined">How to Use This Site</Button> : null}

                        <h3>Other Resources</h3><ul><li><a href="https://novahistory.org/" target="_blank" rel="noreferrer">Northern Virginia History Notes</a></li><li><a href="https://historicwanderings.blogspot.com/" target="_blank" rel="noreferrer">Historic Wanderings</a></li><li><a href="https://honorfairfaxcemeteries.org/" target="_blank" rel="noreferrer">Fairfax County Cemetery Preservation Assocation, Inc.</a></li><li><a href="https://www.arcgis.com/apps/webappviewer/index.html?id=0612f9deb6bb4f0a9fa5cdce0cc75144" target="_blank" rel="noreferrer">Fairfax County Historical Imagery Viewer</a></li></ul><h3>Acknowledgements</h3> Debbie Robison, Kacey Young, Maddy McCoy, the late Margaret C. Peck, Susan R. MacAuley, Clara M. Leigh, Pat S. Johnson, Chris Barbuschak, Laura E. Christiansen, the Thomas Balch Library, Samantha Brice, Yoonjin Chang, the Fairfax County Historic Records Center.<br /><br />
                        <small><em>Header video created with <a href="https://stability.ai/news/stable-video-diffusion-open-ai-video-model" target="_blank" rel="noreferrer">Stable Video Diffusion</a>. Source photographs courtesy of the Fairfax County Public Library Photographic Archive, Thomas Balch Library, and the Peck Collection.</em></small><br /><br />
                        <footer>
                            <div className="left">
                                <strong>Contact: </strong><a href="mailto:heritagedullesgeo@gmail.com">heritagedullesgeo@gmail.com</a>
                            </div>
                            <div className={(!isMobile || isIPad13) ? 'right' : 'left'}>© Gibran Parvez. All rights reserved.</div>
                        </footer>
                    </CardContent>
                </Card>
            </Paper>
        </Modal>
    );
}
