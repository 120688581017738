import React, { useEffect, useState, useRef } from 'react';
import {
    Accordion, AccordionSummary, AccordionDetails, Button, ButtonGroup, Box,
    Card, CardMedia, CardContent, Paper, Modal, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import {
    tutorialVideos
} from '../../app/constants';
import './dialog.css';
import { setTutorial, selectTutorial } from './dialogSlice';

export default function Tutorial() {
    const dispatch = useDispatch();
    const [expandedIndex, setExpandedIndex] = useState(0);
    const tutorialOpen = useSelector(selectTutorial);
    function closeTutorial() {
        dispatch(setTutorial(false));
        setExpandedIndex(0);
    }
    function expandNextVideo() {
        setExpandedIndex(expandedIndex + 1);
    }
    function expandPrevVideo() {
        setExpandedIndex(expandedIndex - 1);
    }

    return (
        <Modal
            open={Boolean(tutorialOpen)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
            disableScrollLock
            className="standard-right-modal-placement"
            style={{ height: '60vh' }}
        >
            <Paper className="tutorial-screen">
                <Typography variant="h5" align="left" gutterBottom>
                    Tutorials
                </Typography>
                <p>The following tutorial videos demonstrate how to use Heritage Dulles.</p>
                <Button variant="contained" className="bottom-button" color={expandedIndex === tutorialVideos.length - 1 ? 'success' : 'primary'} onClick={() => { closeTutorial(); }} size="small">Close</Button>
                {
                    expandedIndex === tutorialVideos.length - 1 ? (
                        <Button className="bottom-button" size="small" color="error">All set? </Button>
                    ) : null
                }
                <ButtonGroup>
                    <Button variant="contained" className="bottom-button" disabled={expandedIndex === 0} onClick={() => { expandPrevVideo(); }} size="small">Previous</Button>
                    <Button variant="contained" className="bottom-button" disabled={expandedIndex === tutorialVideos.length - 1} onClick={() => { expandNextVideo(); }} size="small">Next</Button>
                </ButtonGroup>
                <br /><br />
                {
                    tutorialVideos.map((tutorialVideo, i) => (
                        expandedIndex === i ? (
                            <Card>
                                <CardMedia
                                    className="landscape-video"
                                    component="video"
                                    image={`videos/${tutorialVideo.path}`}
                                    autoPlay
                                    loop
                                    controls
                                    controlsList="nodownload"
                                    style={{ marginBottom: 10 }}
                                />
                                <p style={{ textAlign: 'center', fontWeight: 700, marginBottom: 0 }}>Lesson {i + 1}</p>
                                <Typography variant="h5" align="center" gutterBottom>{tutorialVideo.title}</Typography>
                            </Card>
                        ) : null
                    ))
                }
            </Paper>
        </Modal>
    );
}
