import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    communitySummaries: {},
    currentSummary: null
};

export const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        setCommunitySummaries: (state, action) => {
            state.communitySummaries = action.payload;
        },
        setCurrentSummary: (state, action) => {
            state.currentSummary = action.payload;
        }
    }
});

export const {
    setCommunitySummaries, setCurrentSummary
} = summarySlice.actions;

export const selectCommunitySummaries = (state) => state.summary.communitySummaries;
export const selectCurrentSummary = (state) => state.summary.currentSummary;

export default summarySlice.reducer;
