import { Card, CardContent } from '@material-ui/core';
import {
    ZoomIn
} from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { platGroups } from '../../app/plats';
import { selectCurrentPlats } from './platSlice';
import { centerMap } from '../../app/utilities';

import './plat.css';

export default function PlatDetails(props) {
    const { mapRef } = props;
    const currentPlats = useSelector(selectCurrentPlats);

    function detailSelectedPlats() {
        const flatPlats = [];
        platGroups.forEach((platGroup) => {
            platGroup.plats.forEach((platGroupPlat) => flatPlats.push(platGroupPlat));
        });
        return currentPlats.map((selectedPlat) => flatPlats.find((plat) => plat.layer === selectedPlat));
    }

    function getCaseUrl(chanceryCase) {
        const countyCode = chanceryCase.layer.substring(0, 3);
        return `https://www.lva.virginia.gov/chancery/case_detail.asp?CFN=${countyCode}-${chanceryCase.index}`;
    }

    function centerPlat(row) {
        centerMap(mapRef, row.center, 16);
    }

    return detailSelectedPlats().length > 0 ? (
        <Card className="platDetailsCard">
            <CardContent style={{ padding: 0 }}>
                <TableContainer component={Paper}>
                    <Table m="0" aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Case or Deed</TableCell>
                                <TableCell align="center">Year</TableCell>
                                <TableCell width="62" align="center">Index</TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                // eslint-disable-next-line array-callback-return
                                detailSelectedPlats().map((row) => (
                                    <TableRow key={row.layer}>
                                        <TableCell align="left">{row.case ? row.case : null}</TableCell>
                                        <TableCell align="right">{row.year}</TableCell>
                                        <TableCell align="right">{row.type === 'chancery' ? (<a href={getCaseUrl(row)} target="_blank" rel="noreferrer">{row.index}</a>) : row.index }</TableCell>
                                        <TableCell align="left" padding="none" style={{ paddingTop: 5 }}><ZoomIn onClick={() => { centerPlat(row); }} /></TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    ) : null;
}
