import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    landmarks: false,
    loading: false,
    closerLookLandmarks: [],
    places: [],
    minimized: false
};

export const landmarkSlice = createSlice({
    name: 'landmark',
    initialState,
    reducers: {
        setLandmarks: (state, action) => {
            state.landmarks = action.payload;
        },
        clearLandmarks: (state) => {
            state.landmarks = [];
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setCloserLookLandmarks: (state, action) => {
            state.closerLookLandmarks = action.payload;
        },
        setPlaces: (state, action) => {
            state.places = action.payload;
        },
        setMinimized: (state, action) => {
            state.minimized = action.payload;
        }
    }
});

export const {
    setLandmarks, clearLandmarks, setLoading, setCloserLookLandmarks, setPlace, setMinimized
} = landmarkSlice.actions;

export const selectLandmarks = (state) => state.landmark.landmarks;
export const selectCloserLookLandmarks = (state) => state.landmark.closerLookLandmarks;
export const selectLoading = (state) => state.landmark.loading;
export const selectPlaces = (state) => state.landmark.places;
export const selectMinimized = (state) => state.landmark.minimized;

export default landmarkSlice.reducer;
