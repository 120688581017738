/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
    GeoJSON,
    MapContainer,
    WMSTileLayer,
    Marker,
    Tooltip,
    TileLayer,
    useMap,
    useMapEvents
} from 'react-leaflet';
import L from 'leaflet';
import { isMobile, isIPad13 } from 'react-device-detect';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css'; // Import CSS
import 'leaflet-contextmenu/dist/leaflet.contextmenu'; // Import the plugin

import parse from 'html-react-parser';
import ReactLeafletDriftMarker from 'react-leaflet-drift-marker';
import queryString from 'query-string';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Button } from '@mui/material';
import {
    setNorthernNeckGrants,
    setCivilWarProperties,
    setCemeteries,
    selectNorthernNeckGrantsLayerEnabled,
    selectNorthernNeckGrants,
    selectCivilWarProperties,
    selectCivilWarPropertiesLayerEnabled,
    selectCemeteries,
    select1958PropertiesLayerEnabled,
    setPhotos,
    selectPhotos,
    setPlaces,
    setSharedUrl,
    selectPlaces,
    selectCurrentCommunity,
    setCurrentCommunity,
    selectAerial1937Enabled
} from './mapSlice';
import {
    selectCurrentStory, setCurrentStory
} from '../stories/storySlice';
import {
    mapConfig,
    coordinates,
    communities,
    defaultReqParams,
    endpoints,
    markerIcons,
    linkMarkerIcons,
    availableYears,
    allowedContextMenuClasses
} from '../../app/constants';
import {
    closerLookLandmarks, summaryContent
} from '../../app/content';
import './map.css';
import {
    selectLandmarks,
    setCloserLookLandmarks,
    setLandmarks,
    setLoading,
    selectMinimized,
    setMinimized
} from '../landmark/landmarkSlice';
import { setCurrentCloserLook, selectCurrentCloserLook } from '../closerLook/closerLookSlice';
import { setAbout } from '../dialogs/dialogSlice';
import { selectYear, showAll, setYear } from '../year/yearSlice';
import { selectCurrentPlats } from '../plats/platSlice';
import { setCurrentPhoto } from '../photoView/photoViewSlice';
import { setCurrentSummary } from '../summary/summarySlice';
import { photos as photosLibrary } from '../../app/photos';
import PhotoMarker from './PhotoMarker';
import PhotoView from '../photoView/photoView';
import Landmark from '../landmark/Landmark';
import PlatDetails from '../plats/platDetails';
import {
    coordinateCopyCallback, generateLandGrantColor, generateLandmarkLabel, generatePlaceLabel, nap, openToGoogleCallback, isSummaryPopulated, getFunctionalYear
} from '../../app/utilities';

export default function Map() {
    const dispatch = useDispatch();

    // Map store
    const currentCommunity = useSelector(selectCurrentCommunity);
    const photos = useSelector(selectPhotos);
    const places = useSelector(selectPlaces);
    const currentCloserLook = useSelector(selectCurrentCloserLook);
    const northernNeckGrantsLayerOn = useSelector(
        selectNorthernNeckGrantsLayerEnabled
    );
    const properties1958LayerOn = useSelector(
        select1958PropertiesLayerEnabled
    );
    const currentPlats = useSelector(
        selectCurrentPlats
    );
    const northernNeckGrants = useSelector(selectNorthernNeckGrants);
    const civilWarProperties = useSelector(selectCivilWarProperties);
    const civilWarPropertiesOn = useSelector(selectCivilWarPropertiesLayerEnabled);
    const aerial1937On = useSelector(selectAerial1937Enabled);

    // Landmark store
    const landmarks = useSelector(selectLandmarks);
    const landmarksMinimized = useSelector(selectMinimized);

    const cemeteries = useSelector(selectCemeteries);
    // Story store
    const currentStory = useSelector(selectCurrentStory);

    // Year store
    const currentYear = useSelector(selectYear);

    // Show all landmarks
    const showAllEnabled = useSelector(showAll);

    const [driftMarkerValue, setDriftMarkerValue] = useState({ lat: 38.89612486430293, lng: -77.44526949595726 });
    const mapRef = useRef(null);

    const MapHookWrapper = () => {
        const map = useMap();

        map.on('click', () => {
            map.contextmenu.hide();
        });

        useMapEvents({
            moveend: (e) => {
                const newCenterCoordinates = e.target.getBounds().getCenter();
                const newZoom = e.target.getZoom();
                const urlParams = new URLSearchParams({
                    lat: newCenterCoordinates.lat,
                    lon: newCenterCoordinates.lng,
                    zoom: newZoom
                });
                // window.history.replaceState(null, '', `?${urlParams.toString()}`);
            },
            contextmenu(e) {
                // eslint-disable-next-line no-use-before-define
                if (showContextMenu(e)) {
                    mapRef.current.target.contextmenu.showAt(e.latlng);
                }
            }
        });
    };

    useEffect(() => {
        dispatch(setPhotos(photosLibrary));
        async function retrieveNorthernNeckGrants() {
            axios
                .get(`${endpoints.GEOSERVER}/ows`, {
                    params: {
                        typeNames: 'cite:1725_grants_loco_repro',
                        bbox: `${coordinates.BOUNDS[0][1]},${coordinates.BOUNDS[0][0]},
                        ${coordinates.BOUNDS[1][1]},${coordinates.BOUNDS[1][0]}`,
                        ...defaultReqParams
                    }
                })
                .then(({ data }) => {
                    dispatch(setNorthernNeckGrants(data));
                })
                .catch((error) => Promise.reject(error));
        }
        async function retrieveCivilWarProperties() {
            axios
                .get(`${endpoints.GEOSERVER}/ows`, {
                    params: {
                        typeNames: 'cite:fairfax_1860_properties',
                        bbox: `${coordinates.BOUNDS[0][1]},${coordinates.BOUNDS[0][0]},
                        ${coordinates.BOUNDS[1][1]},${coordinates.BOUNDS[1][0]}`,
                        ...defaultReqParams
                    }
                })
                .then(({ data }) => {
                    dispatch(setCivilWarProperties(data));
                })
                .catch((error) => Promise.reject(error));
        }
        async function retrieveCemeteries() {
            axios.get(`${endpoints.GEOSERVER}/ows`, { params: { typeNames: 'cite:cemeteries', ...defaultReqParams } })
                .then(({ data }) => {
                    const retrievedCemeteries = data.features;
                    dispatch(setCemeteries(retrievedCemeteries));
                })
                .catch((error) => Promise.reject(error));
        }
        async function retrievePlaces() {
            axios.get(`${endpoints.GEOSERVER}/ows`, { params: { typeNames: 'cite:villages', ...defaultReqParams } })
                .then(({ data }) => {
                    const retrievedPlaces = data.features;
                    dispatch(setPlaces(retrievedPlaces));
                })
                .catch((error) => Promise.reject(error));
        }
        if (!northernNeckGrants) {
            retrieveNorthernNeckGrants(dispatch);
        }
        if (!civilWarProperties) {
            retrieveCivilWarProperties(dispatch);
        }
        if (!cemeteries) {
            retrieveCemeteries(dispatch);
        }
        if (!places) {
            retrievePlaces(dispatch);
        }
        dispatch(setCloserLookLandmarks(closerLookLandmarks));
        // We must have closer look landmarks before retrieving landmarks and evaluating params once landmarks call comes back
        async function retrieveAndFilterLandmarksAndPaths() {
            dispatch(setLoading(true));
            axios.get(`${endpoints.GEOSERVER}/ows`, { params: { typeNames: 'cite:landmarks', ...defaultReqParams } })
                .then(({ data }) => {
                    const retrievedLandmarks = data.features;
                    dispatch(setLandmarks(retrievedLandmarks));
                    dispatch(setLoading(false));
                    // eslint-disable-next-line no-use-before-define
                    evaluateQueryParams(queryString.parse(window.location.search), window.location.pathname, retrievedLandmarks);
                }).catch((error) => Promise.reject(error));
        }
        if (!landmarks) {
            retrieveAndFilterLandmarksAndPaths(dispatch);
        }
    }, []);

    useEffect(() => {
        if (currentCommunity !== null) {
            const foundCommunity = communities.find((community) => community.id === currentCommunity);
            if (!landmarksMinimized) {
                dispatch(setMinimized(true));
            }
            if (foundCommunity.year) {
                dispatch(setYear(foundCommunity.year));
            }
            mapRef.current.target.flyTo([foundCommunity.center[0], foundCommunity.center[1]], foundCommunity.zoom);
        }
    }, [currentCommunity]);

    function retrieveSummary(place) {
        const foundId = isSummaryPopulated(null, place, currentYear);
        const foundCommunity = communities.find((community) => community.name === place.properties[`name_${getFunctionalYear(currentYear)}`] || community.altName === place.properties[`name_${getFunctionalYear(currentYear)}`]);
        if (foundId) {
            dispatch(setCurrentSummary(foundId));
            dispatch(setCurrentCommunity(foundCommunity.id));
            mapRef.current.target.flyTo([foundCommunity.center[0], foundCommunity.center[1]], foundCommunity.zoom);
            const urlParams = new URLSearchParams({
                id: foundId
            });
            window.history.replaceState(null, '', `summary?${urlParams.toString()}`);
        }
    }

    function showContextMenu(e) {
        return allowedContextMenuClasses.includes(e.originalEvent.target.classList[0]);
    }

    function evaluateQueryParams(query, queryPath, retrievedLandmarks) { // Third param fixes weird async issue
        if (queryPath === '/landmark') {
            if (!currentCloserLook && query.uid) {
                if (retrievedLandmarks) {
                    const urlLandmark = retrievedLandmarks.filter((feature) => feature.properties.uid === parseInt(query.uid))[0];
                    if (urlLandmark) {
                        query.uid = null;
                        // eslint-disable-next-line no-use-before-define
                        dispatch(setAbout(false));
                        // eslint-disable-next-line no-use-before-define
                        retrieveCloserLook(urlLandmark);
                        dispatch(setSharedUrl(true));
                    }
                }
            }
        } else if (queryPath === '/image') {
            if (query.id) {
                const urlPhoto = photosLibrary.filter((photo) => photo.id === parseInt(query.id))[0];
                if (urlPhoto) {
                    query.id = null;
                    // eslint-disable-next-line no-use-before-define
                    dispatch(setAbout(false));
                    // eslint-disable-next-line no-use-before-define
                    dispatch(setCurrentPhoto(urlPhoto));
                    dispatch(setSharedUrl(true));
                }
            }
        } else if (queryPath === '/summary') {
            if (query.id) {
                const urlSummary = summaryContent[query.id];
                if (urlSummary) {
                    // eslint-disable-next-line no-use-before-define
                    dispatch(setAbout(false));
                    // eslint-disable-next-line no-use-before-define
                    dispatch(setCurrentSummary(query.id));
                    dispatch(setSharedUrl(true));
                    query.id = null;
                }
            }
        } else {
            dispatch(setSharedUrl(false));
        }
    }

    function retrieveCloserLook(landmark) {
        if (closerLookLandmarks.indexOf(landmark.properties.uid) > -1) {
            dispatch(setCurrentCloserLook(landmark));
            mapRef.current.target.setView([landmark.geometry.coordinates[1], landmark.geometry.coordinates[0] - 0.0045], 16);
            const urlParams = new URLSearchParams({
                uid: landmark.properties.uid
            });
            window.history.replaceState(null, '', `landmark?${urlParams.toString()}`);
            return true;
        }
        return null;
    }

    function generateStoryCharacterIcon(character) {
        let iconProperties = null;
        if (character === 'sr71') {
            iconProperties = {
                iconUrl: './png/sr71-circle.png',
                iconSize: [18, 18]
            };
            return new L.Icon(iconProperties);
        }
    }

    function generateIcon(feature) {
        let iconProperties = null;
        if (closerLookLandmarks.indexOf(feature.properties.uid) > -1) {
            if (feature.properties.type in linkMarkerIcons > -1) {
                // Look through markerIcons dictionary
                iconProperties = linkMarkerIcons[feature.properties.type];
            }
        } else if (feature.properties.type in markerIcons > -1) {
            iconProperties = markerIcons[feature.properties.type];
        }
        return new L.Icon(iconProperties);
    }

    function determinePlaceStyling(place) {
        return isSummaryPopulated(null, place, currentYear) ? `place-${currentYear}-clickable` : `place-${currentYear}`;
    }

    function renderYearWMS(givenYear) {
        if (parseInt(givenYear)) {
            const foundAvailableYear = availableYears.find((availableYear) => parseInt(availableYear.id) === givenYear);
            if (mapRef.current) {
                if (mapRef.current.target.getZoom() > mapConfig.MAXZOOM[givenYear]) {
                    mapRef.current.target.setZoom(mapConfig.MAXZOOM[givenYear]);
                }
                mapRef.current.target.setMaxZoom(mapConfig.MAXZOOM[givenYear]);
            }
            return (
                <WMSTileLayer
                    layers={foundAvailableYear.wms}
                    url={`${endpoints.GEOSERVER}/wms`}
                    key={foundAvailableYear.id}
                    format="image/png"
                    BGCOLOR={foundAvailableYear.bgColor || null} // IMPORTANT
                />
            );
        }
        return (
            <TileLayer url={endpoints.OSM} key="now">
                {' '}
            </TileLayer>
        );
    }

    async function iterateStoryPath() {
        for (let i = 0; i < currentStory.path.length; i++) {
            if (i > 0 && !mapRef.current.target.getBounds().contains(currentStory.path[i].position)) {
                mapRef.current.target.setView(currentStory.path[i].position, 16);
            }
            setDriftMarkerValue(currentStory.path[i].position);
            await nap(500);
        }
        await nap(500);
        dispatch(setCurrentStory(null));
    }

    function assessFeatureEligibility(feature) {
        let valid = false;
        if (showAllEnabled) {
            valid = true;
        } else if ((feature.properties.year <= (parseInt(currentYear) + 5)) || currentYear === 'now') {
            if (feature.properties.range) {
                if (feature.properties.range >= parseInt(currentYear)) {
                    valid = true;
                }
            } else if (feature.properties.extant) {
                valid = true;
            }
        }
        return valid;
    }

    function assessPhotoEligibility(photo) {
        let valid = false;
        if (currentYear === 'now') {
            valid = true;
        } else if (photo.subjectYear <= (parseInt(currentYear) + 5)) {
            valid = true;
        }
        return valid;
    }

    return (
        <div className="map">
            {
                currentStory ? (
                    <Button variant="contained" onClick={() => { iterateStoryPath(); }} endIcon={<PlayCircleIcon />} className="run-story">
                        Play
                    </Button>
                ) : null
            }
            <MapContainer
                whenReady={(mapInstance) => {
                    mapRef.current = mapInstance;
                    mapInstance.target.contextmenu.addItem({
                        text: 'Copy coordinates',
                        callback: coordinateCopyCallback
                    });
                    mapInstance.target.contextmenu.addItem({
                        text: 'Show location in Google Maps',
                        callback: openToGoogleCallback
                    });
                }}
                center={coordinates.CENTER}
                zoom={15}
                minZoom={mapConfig.MINZOOM}
                maxZoom={mapConfig.MAXZOOM[currentYear]}
                scrollWheelZoom
                className="map-container"
                maxBounds={coordinates.BOUNDS}
                maxBoundsViscosity={mapConfig.VISCOSITY}
                zoomSnap={mapConfig.ZOOMSNAP}
            >
                <MapHookWrapper />
                {/* <QueryParamWriter /> */}
                {
                    renderYearWMS(currentYear)
                }
                {properties1958LayerOn
                    ? (
                        <WMSTileLayer layers="1958_properties_dulles_condemned" url={`${endpoints.GEOSERVER}/wms`} transparent opacity={0.5} format="image/png" zIndex="100" />
                    ) : null}
                {northernNeckGrants && northernNeckGrantsLayerOn
                    ? northernNeckGrants.features.map((grant) => (
                        <GeoJSON
                            key={grant.id}
                            data={grant}
                            style={() => ({
                                color: generateLandGrantColor(grant.properties.ACRES, 15000),
                                weight: 1,
                                opacity: 1.0,
                                dashArray: 4
                            })}
                        >
                            <Tooltip
                                className="land-grant"
                                direction="top"
                                offset={[0, 5]}
                                opacity={1}
                                permanent
                            >
                                {/* { revolutionaryVets[grant.properties.OBJECTID] ? (
                                    <span>
                                        <img
                                            src="png/revolutionary-vet.png"
                                            style={{
                                                width: 20
                                            }}
                                        />
                                        <br />
                                    </span>
                                ) : null }
                                {' '} */}
                                {grant.properties.OWNER} <br />{' '}
                                <span
                                    style={{
                                        fontSize: '10px',
                                        fontFamily: 'Times'
                                    }}
                                >
                                    {' '}
                                    {grant.properties.ACRES} acres,{' '}
                                    {grant.properties.YEAR}{' '}
                                </span>{' '}
                                <br />
                                <span
                                    style={{
                                        fontSize: '8px',
                                        fontFamily: 'Times',
                                        opacity: 0.25
                                    }}
                                >
                                    {' '}
                                    {grant.properties.DEED}
                                </span>{' '}
                            </Tooltip>{' '}
                        </GeoJSON>
                    ))
                    : null}
                {civilWarProperties && civilWarPropertiesOn
                    ? civilWarProperties.features.map((grant) => (
                        <GeoJSON
                            key={grant.id}
                            data={grant}
                            style={() => ({
                                color: generateLandGrantColor(grant.properties.acreage, 2100),
                                weight: 1,
                                opacity: 1.0,
                                dashArray: 4
                            })}
                        >
                            <Tooltip direction="top" offset={[0, 5]} opacity={1} permanent>
                                <span
                                    style={{
                                        opacity: 0.5,
                                        fontSize: '12px',
                                        fontFamily: 'Times'
                                    }}
                                >{grant.properties.owner}
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: '10px',
                                        opacity: 0.5
                                    }}
                                >
                                    {' '}
                                    {grant.properties.acreage}{' '}
                                    acres{' '}
                                </span>{' '}
                                <br />
                                <span
                                    style={{
                                        fontSize: '8px',
                                        opacity: 0.25
                                    }}
                                >
                                    {' '}
                                    {grant.properties.deed}
                                </span>{' '}
                            </Tooltip>{' '}
                        </GeoJSON>
                    ))
                    : null}
                {currentPlats ? currentPlats.map((plat) => (
                    <WMSTileLayer layers={plat} url={`${endpoints.GEOSERVER}/wms`} transparent format="image/png" zIndex="100" />
                )) : null}

                {aerial1937On ? <WMSTileLayer layers="1937" url={`${endpoints.GEOSERVER}/wms`} transparent format="image/png" zIndex="1" /> : null}
                {cemeteries ? (
                    cemeteries.filter((cemetery) => assessFeatureEligibility(cemetery)).map((cemetery) => (
                        <GeoJSON
                            style={() => ({
                                color: '#96946f',
                                weight: 1,
                                dashArray: 2
                            })}
                            data={cemetery}
                            key={`${currentYear}_${cemetery.id}`}
                        >
                            <Tooltip className={`cemetery-${currentYear}`} direction="right" offset={[0, 5]} opacity={0.5} permanent>
                                ✝ {cemetery.properties.name}{' '}
                            </Tooltip>{' '}
                        </GeoJSON>
                    ))
                ) : null}
                {places && currentYear !== 'now' && currentYear !== 1994 ? (
                    places.map((place) => (
                        <Marker
                            key={`${place.id}-${currentYear}`}
                            title={place.properties.name_1860}
                            position={[place.geometry.coordinates[1], place.geometry.coordinates[0]]}
                            icon={L.divIcon({ iconSize: [100, 30], iconAnchor: [70, -35], className: 'place-marker' })}
                            eventHandlers={{
                                click: () => {
                                    retrieveSummary(place);
                                }
                            }}
                        >
                            <Tooltip
                                className={determinePlaceStyling(place)}
                                direction="top"
                                offset={[-45, 35]}
                                opacity={1}
                                permanent
                                interactive
                            >
                                {generatePlaceLabel(place, currentYear)}{' '}
                            </Tooltip>{' '}
                        </Marker>
                    ))
                ) : null}
                {photos ? (
                    photos.filter((photo) => assessPhotoEligibility(photo)).map((photo) => (photo.coordinates ? (
                        <PhotoMarker photo={photo} position={photo.coordinates} key={`photo${photo.id}`} />
                    ) : null))
                ) : null }
                {landmarks
                    ? landmarks.filter((feature) => assessFeatureEligibility(feature)).map((feature) => (
                        <Marker
                            key={`${currentYear}_${feature.id}`}
                            // title={feature.properties.owner_1860}
                            icon={generateIcon(feature)}
                            position={[
                                feature.geometry.coordinates[1],
                                feature.geometry.coordinates[0]
                            ]}
                            eventHandlers={{
                                click: () => {
                                    retrieveCloserLook(feature);
                                }
                            }}
                            zIndex={500}
                        >
                            <Tooltip
                                direction="top"
                                offset={[-30, -5]}
                                opacity={1}
                                className={`landmark-${currentYear}`}
                                interactive
                                permanent
                            >
                                {' '}
                                {parse(
                                    generateLandmarkLabel(feature, currentYear)
                                )}{' '}
                            </Tooltip>{' '}
                        </Marker>
                    ))
                    : null}
                {' '}
                { currentStory ? (
                    <ReactLeafletDriftMarker
                        position={driftMarkerValue}
                        icon={generateStoryCharacterIcon('sr71')}
                        duration={500}
                    />
                ) : null}
                <PhotoView mapRef={mapRef} />
                <PlatDetails mapRef={mapRef} />
                { (!isMobile || isIPad13) ? <Landmark mapRef={mapRef} /> : null }
            </MapContainer>
        </div>
    );
}
