import React from 'react';
import { Marker } from 'react-leaflet';
import leaflet from 'leaflet';
import { useDispatch } from 'react-redux';
import { setCurrentPhoto } from '../photoView/photoViewSlice';
import './map.css';
import {
    getFileNameWithoutExtension
} from '../../app/utilities';

export default function PhotoMarker(props) {
    const { position, photo } = props;
    // const map = useMap();
    const dispatch = useDispatch();
    const photoThumbnail = leaflet.divIcon({
        html: `<div style="background-image: url('photos/thumb_${getFileNameWithoutExtension(photo.filePath)}.jpg');" class="photo-marker"></div>`
    });

    return (
        <div>
            <Marker
                icon={photoThumbnail}
                position={position}
                eventHandlers={{
                    click: () => {
                        dispatch(setCurrentPhoto(photo));
                    }
                }}
                key={`photomarker${photo.id}`}
                zIndex={100}
            />
        </div>
    );
}
