import React from 'react';
import {
    Checkbox, Input, MenuItem, ListItemText, FormControl, Select, InputLabel
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectNorthernNeckGrantsLayerEnabled, setNorthernNeckGrantsLayerEnabled, selectCivilWarPropertiesLayerEnabled, setCivilWarPropertiesLayerEnabled, set1958PropertiesLayerEnabled, select1958PropertiesLayerEnabled, selectAerial1937Enabled, setAerial1937Enabled
} from '../map/mapSlice';
import './topBar.css';

export default function SpecialLayers() {
    const dispatch = useDispatch();
    const properties1958LayerEnabled = useSelector(select1958PropertiesLayerEnabled);
    const northernNeckGrantsLayerEnabled = useSelector(selectNorthernNeckGrantsLayerEnabled);
    const civilWarPropertiesLayerEnabled = useSelector(selectCivilWarPropertiesLayerEnabled);
    const aerial1937Enabled = useSelector(selectAerial1937Enabled);
    const currentSpecialLayers = [];
    let currentSpecialLayersString = null;
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        currentSpecialLayersString = typeof value === 'string' ? value.split(',') : value;
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300, marginTop: -1 }} variant="filled" size="small">
                <InputLabel sx={{ marginTop: '0.5em' }}>Special Layers</InputLabel>
                <Select
                    multiple
                    onChange={handleChange}
                    input={<Input />}
                    value={currentSpecialLayers}
                    style={
                        {
                            backgroundColor: 'white', height: 36, borderRadius: 4, padding: 5, paddingTop: 6, paddingLeft: 7
                        }
                    }
                >
                    <MenuItem key="1729" value="1729" onClick={() => { dispatch(setNorthernNeckGrantsLayerEnabled(!northernNeckGrantsLayerEnabled)); }}>
                        <Checkbox
                            checked={northernNeckGrantsLayerEnabled}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <ListItemText primary="Northern Neck Land Grants" />
                    </MenuItem>
                    <MenuItem key="1860" value="1860" onClick={() => { dispatch(setCivilWarPropertiesLayerEnabled(!civilWarPropertiesLayerEnabled)); }}>
                        <Checkbox
                            checked={civilWarPropertiesLayerEnabled}
                            inputProps={{ 'aria-label': 'primary checkbox' }}

                        />
                        <ListItemText primary="1860 Properties" />
                    </MenuItem>
                    <MenuItem key="1958" value="1958" onClick={() => { dispatch(set1958PropertiesLayerEnabled(!properties1958LayerEnabled)); }}>
                        <Checkbox
                            checked={properties1958LayerEnabled}
                            inputProps={{ 'aria-label': 'primary checkbox' }}

                        />
                        <ListItemText primary="1958 Properties" />
                    </MenuItem>
                    <MenuItem key="1937" value="1937" onClick={() => { dispatch(setAerial1937Enabled(!aerial1937Enabled)); }}>
                        <Checkbox
                            checked={aerial1937Enabled}
                            inputProps={{ 'aria-label': 'primary checkbox' }}

                        />
                        <ListItemText primary="1937 Aerial" />
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}
