import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    summaries: {},
    citations: {},
    yearCitations: {},
    slaveryInvolved: [],
    pressClippings: {},
    currentCloserLook: null,
    currentPressClipping: null
};

export const closerLookSlice = createSlice({
    name: 'closerLook',
    initialState,
    reducers: {
        setSummaries: (state, action) => {
            state.summaries = action.payload;
        },
        setCitations: (state, action) => {
            state.citations = action.payload;
        },
        setYearCitations: (state, action) => {
            state.yearCitations = action.payload;
        },
        setPressClippings: (state, action) => {
            state.pressClippings = action.payload;
        },
        setCurrentCloserLook: (state, action) => {
            state.current = action.payload;
        },
        setSlaveryInvolved: (state, action) => {
            state.slaveryInvolved = action.payload;
        },
        setCurrentPressClipping: (state, action) => {
            state.currentPressClipping = action.payload;
        }
    }
});

export const {
    setSummaries, setCitations, setYearCitations, setPressClippings, setCurrentCloserLook, setSlaveryInvolved, setCurrentPressClipping
} = closerLookSlice.actions;

export const selectSummaries = (state) => state.closerLook.summaries;
export const selectCitations = (state) => state.closerLook.citations;
export const selectYearCitations = (state) => state.closerLook.yearCitations;
export const selectSlaveryInvolved = (state) => state.closerLook.slaveryInvolved;
export const selectPressClippings = (state) => state.closerLook.pressClippings;
export const selectCurrentCloserLook = (state) => state.closerLook.current;
export const selectCurrentPressClipping = (state) => state.closerLook.currentPressClipping;

export default closerLookSlice.reducer;
