/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isIPad13 } from 'react-device-detect';
import {
    ImageList, ImageListItem, ImageListItemBar, Modal, Paper, Typography, ButtonGroup, Grid
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import './closerLook.css';
import parse from 'html-react-parser';
import {
    summaries as writtenSummaries, citations as writtenCitations, yearCitations as writtenYearCitations, pressClippings as press, slavery, slaveryCitations, summaryPhotos
} from '../../app/content';
import { photos as photosLibrary } from '../../app/photos';
import * as closerLookSlice from './closerLookSlice';
import { setSharedUrl } from '../map/mapSlice';
import { setCurrentPhoto } from '../photoView/photoViewSlice';
import { selectYear } from '../year/yearSlice';
import { getFunctionalYear, isSummaryPopulated, shareLandmarkLink } from '../../app/utilities';
// import styles from './Counter.module.css';

export default function CloserLook() {
    const [openToast, setOpenToast] = React.useState(false);
    const summaries = useSelector(closerLookSlice.selectSummaries);
    const current = useSelector(closerLookSlice.selectCurrentCloserLook);
    const citations = useSelector(closerLookSlice.selectCitations);
    const yearCitations = useSelector(closerLookSlice.selectYearCitations);
    const currentYear = useSelector(selectYear);
    const pressClippings = useSelector(closerLookSlice.selectPressClippings);
    const currentPressClipping = useSelector(closerLookSlice.selectCurrentPressClipping);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closerLookSlice.setSummaries(writtenSummaries));
        dispatch(closerLookSlice.setCitations(writtenCitations));
        dispatch(closerLookSlice.setYearCitations(writtenYearCitations));
        dispatch(closerLookSlice.setPressClippings(press));
        dispatch(closerLookSlice.setSlaveryInvolved(slavery));
    }, []);
    function closeCloserLook() {
        dispatch(closerLookSlice.setCurrentCloserLook(null));
        dispatch(closerLookSlice.setCurrentPressClipping(null));
        dispatch(setSharedUrl(null));
    }
    function handleClose(event, reason) {
        if (reason === 'backdropClick') {
            closeCloserLook();
        }
    }
    function retrieveSummaryPhoto(section) {
        const currentSummaryPhotos = summaryPhotos[current.properties.uid];
        if (currentSummaryPhotos) {
            const currentSummaryPhotoSectionId = currentSummaryPhotos[section];
            if (currentSummaryPhotoSectionId) {
                const currentSummaryPhoto = photosLibrary.find((photo) => photo.id === currentSummaryPhotoSectionId);
                return (
                    <figure
                        className="standard-photo"
                        style={{
                            float: section === 'who' ? 'right' : 'right'
                        }}
                    >
                        <img
                            src={currentSummaryPhoto.filePath}
                            alt={currentSummaryPhoto.description}
                            loading="lazy"
                            onClick={() => { if (currentSummaryPhoto.coordinates) { dispatch(setCurrentPhoto(currentSummaryPhoto)); } }}
                        />
                        <figcaption className="standard-photo-caption">{currentSummaryPhoto.description}</figcaption>
                    </figure>
                );
            // eslint-disable-next-line no-else-return
            } else {
                return null;
            }
        }
        return null;
    }

    function shareAction(uid) {
        shareLandmarkLink(uid);
        setOpenToast(true);
    }
    function closeToast() {
        setOpenToast(false);
    }

    return (
        <div>
            {
                current ? (
                    <Modal
                        open={Boolean(current)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableEnforceFocus
                        disableScrollLock
                        hideBackdrop
                        className={(!isMobile || isIPad13) ? 'closer-look-modal-placement' : 'closer-look-modal-placement-mobile'}
                        onClose={(event, reason) => handleClose(event, reason)}
                    >
                        <Paper className={(!isMobile || isIPad13) ? 'closer-look' : 'closer-look-mobile'}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    { slavery.indexOf(current.properties.uid) > -1 ? <span><img src="png/bondage.png" className="badge" alt="Bondage icon" title={`This location housed enslaved peoples. Footnote ${slaveryCitations[current.properties.uid]}`} /></span> : null}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6" align="center" className="village-header" gutterBottom>
                                        <a href={`summary?id=${isSummaryPopulated(null, null, null, current.properties.associated)}`}>{current.properties.associated}</a>
                                    </Typography>
                                    <Typography variant="h4" align="center" gutterBottom>
                                        {current.properties.estate_name || current.properties.name}
                                    </Typography>
                                    <Typography variant="h5" align="center" style={{ marginTop: '-0.5em' }} gutterBottom>{current.properties.approximation}{Math.trunc(current.properties.year)}<sup style={{ fontSize: '0.5em' }}>{yearCitations[current.properties.uid]}</sup></Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton size="small" onClick={() => closeCloserLook()} className="close-button"><CloseIcon /></IconButton>
                                    <IconButton size="small" variant="outlined" className="share-button" onClick={() => shareAction(current.properties.uid)}><ShareIcon /></IconButton>
                                </Grid>
                            </Grid>
                            {current.properties.uid in summaries
                                ? (
                                    <span>
                                        <div>
                                            <Typography variant="h5" gutterBottom>
                                                <strong>Who</strong>
                                            </Typography>
                                            { retrieveSummaryPhoto('who') }
                                            <Typography variant="body1" gutterBottom>
                                                {parse(summaries[current.properties.uid].who)}
                                            </Typography>
                                            <Typography variant="h5" gutterBottom>
                                                <strong>What</strong>
                                            </Typography>
                                            { retrieveSummaryPhoto('what') }
                                            <Typography variant="body1" gutterBottom>
                                                {parse(summaries[current.properties.uid].what)}
                                            </Typography>
                                            { (!isMobile || isIPad13) && 'press' in summaries[current.properties.uid]
                                                ? (
                                                    <span>
                                                        <Typography variant="h5" gutterBottom>
                                                            <strong>Press</strong>
                                                        </Typography>
                                                        <ImageList cols={4} rowHeight={164}>
                                                            {
                                                                summaries[current.properties.uid].press.map((pressClippingIndex) => (
                                                                    <ImageListItem key={pressClippingIndex} className="press-image" onClick={() => { dispatch(closerLookSlice.setCurrentPressClipping(pressClippingIndex)); }}>
                                                                        <img
                                                                            src={`${pressClippings[pressClippingIndex].filePath}?w=164&h=164&fit=crop&auto=format`}
                                                                            srcSet={`${pressClippings[pressClippingIndex].filePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                                        />
                                                                        <p>{summaries[current.properties.uid].press}</p>
                                                                        <ImageListItemBar
                                                                            title={`${pressClippings[pressClippingIndex].year}: ${pressClippings[pressClippingIndex].title}`}
                                                                            subtitle={parse(pressClippings[pressClippingIndex].citation)}
                                                                        />
                                                                    </ImageListItem>
                                                                ))
                                                            }
                                                        </ImageList>
                                                    </span>
                                                )
                                                : null }
                                        </div>
                                        <Typography variant="h6" gutterBottom>
                                            <strong>Footnotes</strong>
                                        </Typography>
                                        <ol className="biblio">
                                            {citations[current.properties.uid].map((citation, index) => <li key={index}>{parse(citation)}</li>)}
                                        </ol>
                                    </span>
                                )

                                : null}
                            <Button variant="contained" className="close-button" onClick={() => { closeCloserLook(); }} size="small">Close</Button>
                            <br />
                            <br />
                            <Snackbar
                                open={openToast}
                                autoHideDuration={3000}
                                onClose={closeToast}
                                message="Link copied"
                            />
                        </Paper>
                    </Modal>
                ) : null
            }
            {
                currentPressClipping !== null ? (
                    <Paper className="press-dialog" elevation={12}>
                        <IconButton size="small" onClick={() => { dispatch(closerLookSlice.setCurrentPressClipping(null)); }} className="close-press-button"><CloseIcon /></IconButton>
                        <br />
                        <br />
                        <Typography variant="h5" align="center" gutterBottom>
                            { pressClippings[currentPressClipping].title }
                        </Typography>
                        <img
                            src={pressClippings[currentPressClipping].filePath}
                            style={{ width: '100%' }}
                        />
                        <br />
                        <p style={{ textAlign: 'center', marginTop: '2.5em' }}>
                            {parse(pressClippings[currentPressClipping].citation)}
                        </p>{' '}
                    </Paper>
                ) : null
            }
        </div>
    );
}
