import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPhoto: null,
    maximized: false
};

export const photoViewSlice = createSlice({
    name: 'photoView',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setCurrentPhoto: (state, action) => {
            state.currentPhoto = action.payload;
        },
        setMaximized: (state, action) => {
            state.maximized = action.payload;
        }
    }
});

export const { setCurrentPhoto, setMaximized } = photoViewSlice.actions;

export const selectCurrentPhoto = (state) => state.photoView.currentPhoto;
export const selectMaximized = (state) => state.photoView.maximized;

export default photoViewSlice.reducer;
