import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    splash: true,
    tutorial: false,
    about: false
};

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSplash: (state, action) => {
            state.splash = action.payload;
        },
        setTutorial: (state, action) => {
            state.tutorial = action.payload;
        },
        setAbout: (state, action) => {
            state.about = action.payload;
        }
    }
});

export const { setAbout, setSplash, setTutorial } = dialogSlice.actions;

export const selectAbout = (state) => state.dialog.about;
export const selectSplash = (state) => state.dialog.splash;
export const selectTutorial = (state) => state.dialog.tutorial;

export default dialogSlice.reducer;
