import { configureStore } from '@reduxjs/toolkit';
import landmarkReducer from '../features/landmark/landmarkSlice';
import mapReducer from '../features/map/mapSlice';
import yearReducer from '../features/year/yearSlice';
import closerLookReducer from '../features/closerLook/closerLookSlice';
import photoViewReducer from '../features/photoView/photoViewSlice';
import storyReducer from '../features/stories/storySlice';
import platReducer from '../features/plats/platSlice';
import dialogReducer from '../features/dialogs/dialogSlice';
import summaryReducer from '../features/summary/summarySlice';

export const store = configureStore({
    reducer: {
        landmark: landmarkReducer,
        map: mapReducer,
        year: yearReducer,
        closerLook: closerLookReducer,
        photoView: photoViewReducer,
        story: storyReducer,
        plat: platReducer,
        dialog: dialogReducer,
        summary: summaryReducer
    }
});
