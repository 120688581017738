import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import {
    Minimize, WebAsset
} from '@material-ui/icons';
import {
    Autocomplete,
    Button,
    ButtonGroup,
    TextField,
    Typography
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
    landmarkColumns,
    availableYears
} from '../../app/constants';
import * as landmarkSlice from './landmarkSlice';
import './landmark.css';
import { setYear, selectYear } from '../year/yearSlice';
import { centerMap } from '../../app/utilities';
// import styles from './Counter.module.css';

export default function Landmark(props) {
    const { mapRef } = props;
    const landmarks = useSelector(landmarkSlice.selectLandmarks);
    const currentYear = useSelector(selectYear);
    const minimized = useSelector(landmarkSlice.selectMinimized);
    const dispatch = useDispatch();
    let rows = null;

    if (landmarks) {
        rows = landmarks.map((landmark, i) => ({
            id: i,
            key: i,
            label: landmark.properties.estate_name || landmark.properties.name,
            geometry: landmark.geometry,
            ...landmark.properties
        })).filter((landmark) => landmark.label !== 'House' && landmark.label !== '');
    }

    const buttonStyle = {
        position: 'absolute',
        right: 5,
        top: 8
    };

    function selectLandmark(selected) {
        if (selected) {
            centerMap(mapRef, [selected.geometry.coordinates[1], selected.geometry.coordinates[0] - 0.0045], 17);
            if (currentYear < selected.year) {
                dispatch(setYear(availableYears.find((availableYear) => availableYear >= selected.year)));
            }
        }
    }

    return (
        <Card className="landmark-card">
            <CardContent>
                <Typography sx={{ fontSize: 18, fontWeight: 'bold' }} color="text.primary" gutterBottom>
                    Landmarks
                </Typography>
                { rows ? (
                    <Autocomplete
                        size="small"
                        blurOnSelect
                        options={rows}
                        onChange={(event, value) => { selectLandmark(value); }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        renderInput={(params) => <TextField {...params} label="Search Landmarks" />}
                    />
                ) : null}
                <div className={minimized ? 'landmark-container minimized' : 'landmark-container'}>
                    <ButtonGroup disableElevation style={buttonStyle}>
                        <Button size="small" aria-label="Minimize" onClick={() => { dispatch(landmarkSlice.setMinimized(!minimized)); }}>
                            {minimized ? <WebAsset /> : <Minimize /> }
                        </Button>&nbsp;
                    </ButtonGroup>
                    <br />
                    { rows ? (
                        <DataGrid
                            rows={rows}
                            columns={landmarkColumns}
                            onRowClick={(selected) => {
                                selectLandmark(selected.row);
                            }}
                            // pageSize={10}
                            // rowsPerPageOptions={[5]}
                            density="compact"
                            hideFooter
                            autoHide
                        />
                    ) : null}
                </div>
            </CardContent>
        </Card>
    );
}
