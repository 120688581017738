import React from 'react';
import {
    Button,
    Card, CardMedia, CardContent, Paper, Modal, Typography
} from '@mui/material';
import { isMobile, isIPad13 } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';

import './dialog.css';
import { setSplash, selectSplash } from './dialogSlice';

export default function Splash() {
    const dispatch = useDispatch();
    const splashOpen = useSelector(selectSplash);
    function closePhotoView() {
        dispatch(setSplash(false));
        window.localStorage.setItem('acceptedTerms', Date.now());
    }
    function validAcceptance() {
        const timestamp = window.localStorage.getItem('acceptedTerms');
        if (timestamp) {
            // Calculate the current time in milliseconds
            const currentTime = new Date().getTime();
            // Calculate the time difference in milliseconds
            const timeDifference = currentTime - timestamp;
            // Calculate the number of milliseconds in 8 hours
            const eightHoursInMilliseconds = 8 * 60 * 60 * 1000;

            // Check if 8 hours have passed
            return timeDifference <= eightHoursInMilliseconds;
        }
        return false;
    }

    return (
        validAcceptance() ? null : (
            <Modal
                open={Boolean(splashOpen)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
                disableScrollLock
                className="centered-modal-placement"
            >
                <Paper className={(!isMobile || isIPad13) ? 'splash-screen' : 'splash-screen-mobile'}>
                    <Card sx={{ height: '100%' }}>
                        <div className="splash-logo">
                            <img src="/bar_logo.png" />
                        </div>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image="/photos/peck_farm_barns.jpg"
                            sx={{ opacity: 0.5 }}
                        />
                        <CardContent>
                            <p className="legal">Heritage Dulles is not liable for any use of or reliance upon this map or data, or any information contained herein. It makes no warranty, expressed or implied, as to its accuracy, completeness, or fitness for use of any purpose. The cartographic depictions made available on this website exclusively reflect the period explicitly delineated herein.</p>
                            <p>By proceeding, you accept these terms.</p>
                        </CardContent>
                        <br />
                        <br />
                        <Button variant="contained" onClick={() => { closePhotoView(); }} style={{ position: 'absolute', bottom: '2em', right: '2em' }} size="small">Accept</Button>
                    </Card>

                </Paper>
            </Modal>
        )
    );
}
